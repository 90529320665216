.defaultSection {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    .logoBox {
        position:relative;
        height: 20%;
        width: 100%;
        display: flex;

        .logo {
            height: 20vh;
            width: auto;
            margin: auto;
            transition: opacity 0.6s;
            opacity: 0;

            &.show {
                opacity: 1;
            }
        }

        .kids {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .playerSection {
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .potato {
            height: 30vh;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            user-select: all;
        }

        .text {
            font-size: 6vh;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            color: black;
            font-family: Albus;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 2vh;
        top: 10vh;

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: #212B3D;
                margin: 0;
                font: inherit;
                width: 4vh;
                height: 4vh;
                border-radius: 1vh;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;

                &:hover {
                    background-color: #101C2D !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: 2vh;
                height: 2vh;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: white;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                padding-left: 1vh;
                font-size: 3vh;
                font-family: 'Albus';
                color: #212529;
                z-index: 1;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }

    .button {
        font-size: 2.5vh;
        text-align: center;
        color: white;
        height: fit-content;
        width: fit-content;
        padding: 0.25vh;
        padding-left: 3vh;
        padding-right: 3vh;
        margin-left: auto;
        margin-right: auto;
        background-color: #10232B;
        border-radius: 1vh;
        font-family: 'Albus';
        user-select: inherit !important;
        cursor: pointer;
        transform: scale(1);

        &:active {
            transform: scale(1.2);
        }
    }


    .gameOverSection {
        height: 30%;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;

        .resultText {
            font-size: 8vh;
            font-weight: bold;
            font-style: italic;
            text-align: center;
            margin: auto;
            color: black;
            margin-top: unset;
            margin-bottom: unset;
            font-family: Albus;
        }

        .scoreText {
            font-size: 5vh;
            text-align: center;
            margin: auto;
            color: black;
            margin-top: unset;
            font-family: Albus;
        }
    }
}
