div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.tutorialContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: #FFF100;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    background: white;
    background-image: url('../images/Host/BG.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index: 0;

    .text {
        text-align: center;
        font-size: 6vh;
        font-family: "Albus";
        color: black;
        transform: scale(0);
        transition: transform 0.5s cubic-bezier(0.45, 0, 0.55, 1);
        margin-bottom: 5vh;

        &.show {
            transform: scale(1);
            transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
        }

        &.larger {
            font-size: 15vh;
        }
    }

    .voteCount {
        position: absolute;
        margin: auto;
        top: 2vh;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 5vh;
        font-family: "Albus";
        color: black;
    }

    .step {
        display: flex;
        flex-direction: column;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.5s cubic-bezier(0.45, 0, 0.55, 1);
        width: fit-content;
        height: fit-content;

        &.show {
            opacity: 1;
        }
    }

    .stepOne {

        .colourList {
            width: 75%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 2vh;
            margin: auto;

            .break {
                flex-basis: 100%;
                height: 0;
            }

            .colour {
                width: 15vh;
                /*height: 5vh;*/
                transform: scale(0);
                transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);

                &.show {
                    transform: scale(1);
                }

                .colourBlob {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .stepTwo {
        display: flex;
        flex-direction: column;
        margin: auto;

        .questionBox {


            .questionText {
                width: 65%;
                text-align: center;
                margin: auto;
                bottom: 30vh;
                top: 0;
                left: 0;
                right: 0;
                color: black;
                font-family: "Albus";
                font-size: 8.5vh;
                line-height: 1;
                display: flex;
                align-items: end;
                justify-content: center;
                transform: scale(0);
                transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);

                &.show {
                    transform: scale(1);
                }
            }

            .numberText {
                margin: auto;
                left: 0;
                right: 0;
                bottom: 0;
                top: 15vh;
                height: fit-content;
                width: fit-content;
                transform: scale(0);
                transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);

                &.show {
                    transform: scale(1);
                }

                &.showNumbers {
                    transition: 0.5s transform cubic-bezier(0.34, 1.56, 0.64, 1), 0.5s opacity;
                    transform: scale(1);
                    opacity: 1;
                }

                .blob {
                    position: relative;
                    height: 20vh;
                    width: auto;
                    opacity: 1;
                }

                .blobText {
                    position: absolute;
                    margin: auto;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    font-family: "Albus";
                    text-align: center;
                    color: white;
                    font-size: 8.5vh;
                    height: fit-content;
                    width: fit-content;
                }
            }
        }
    }

    .stepThree {
        .rowBox {
            display: flex;
            flex-direction: row;
            margin: auto;
            justify-content: center;
            align-items: center;
            gap: 10vh;

            .phoneAnimBox {
                height: auto;
                width: 22.5%;
                transform: translateY(100vh);
                transition: transform 0.5s cubic-bezier(0.45, 0, 0.55, 1);

                &.show {
                    transform: translateY(0vh);
                }
            }
        }
    }

    .stepFour {
        .playerColBox {
            width: 100vw;
            height: 100vh;
            position: absolute;
            margin: auto;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            .playerColumn {
                position: absolute;
                width: 20%;
                height: 75%;
                margin: auto;
                bottom: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                transition: all 0.75s;
                z-index: 5;

                &.left {
                    align-items: start;
                    left: -30%;

                    &.show {
                        left: 0;
                    }

                    .playerLine {
                        margin-right: auto;
                        border-top-right-radius: 1vh;
                        border-bottom-right-radius: 1vh;
                        transform: translateX(-100%);
                    }
                }

                &.right {
                    right: -30%;
                    align-items: end;

                    &.show {
                        right: 0;
                    }

                    .playerLine {
                        margin-left: auto;
                        border-top-left-radius: 1vh;
                        border-bottom-left-radius: 1vh;
                        transform: translateX(100%);
                    }
                }

                .playerLine {
                    width: 80%;
                    height: 0.5vh;
                    background: #BDCCD4;
                    transition: 2s transform;

                    &.showLine {
                        transform: translateX(0%);
                    }
                }
            }
        }

        .colourList {
            width: 75%;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 1vh;
            margin: auto;

            .break {
                flex-basis: 100%;
                height: 0;
            }

            .colour {
                width: 17.5vh;
                /*height: 5vh;*/
                transform: scale(0);
                transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);

                &.show {
                    transform: scale(1);
                }

                .colourBlob {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .stepFive {
        .rowBox {
            display: flex;
            flex-direction: row;
            margin: auto;
            justify-content: center;
            align-items: center;
            gap: 7.5vh;

            .phoneAnimBox {
                height: auto;
                width: 18%;
                transform: translateY(100vh);
                transition: transform 0.5s cubic-bezier(0.45, 0, 0.55, 1);

                &.show {
                    transform: translateY(0vh);
                }
            }
        }
    }
}
