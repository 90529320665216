.buttons {
    display: flex;
    flex-direction: column;
    gap: 2vh;

    .skipBox {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: fit-content;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;

        input[type="checkbox"] {
            -webkit-appearance: none;
            appearance: none;
            background-color: #212B3D;
            margin: 0;
            font: inherit;
            width: 4vh;
            height: 4vh;
            border-radius: 1vh;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;

            &:hover {
                background-color: #101C2D !important;
            }
        }

        input[type="checkbox"]::before {
            content: "";
            width: 2vh;
            height: 2vh;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            transform-origin: center;
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            /* Windows High Contrast Mode */
            background-color: white;
        }

        input[type="checkbox"]:checked::before {
            transform: scale(1);
        }

        label[for="checkbox"] {
            padding-left: 1vh;
            font-size: 3vh;
            font-family: 'Albus';
            color: #212529;
            z-index: 1;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .button {
        font-size: 2.5vh;
        text-align: center;
        color: white;
        height: fit-content;
        width: fit-content;
        padding: 0.25vh;
        padding-left: 3vh;
        padding-right: 3vh;
        margin-left: auto;
        margin-right: auto;
        background-color: #10232B;
        border-radius: 1vh;
        font-family: 'Albus';
        user-select: inherit !important;
        cursor: pointer;
        transform: scale(1);

        &:active {
            transform: scale(1.2);
        }
    }
}

.bigButtons {
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 11;

    .contentFilter {
        width: 45vh;
        height: 5vh;

        .bar {
            height: 1.75vh;
            left: 3vh;
            width: calc(100% - 6vh);
        }

        .bar::-webkit-slider-thumb {
            height: 5vh;
            width: 5vh;
        }

        .icons {

            img {
                transform: translate(0, -10vh);
                height: 15vh;
            }
        }
    }

    .button {
        font-size: 10vh;
        font-family: "Albus";
        padding: 3vh;
        margin: 1vh;
        line-height: 1;
        background-color: #10232B;
        color: white;
        border: none;
        border-radius: 2vh;

        &.smaller {
            font-size: 6vh;
        }
    }

    .skipBox {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: fit-content;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        //margin-top: -4vh;

        input[type="checkbox"] {
            -webkit-appearance: none;
            appearance: none;
            background-color: #212B3D;
            margin: 0;
            font: inherit;
            width: 7.5vh;
            height: 7.5vh;
            border-radius: 2vh;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;

            &:hover {
                background-color: #101C2D !important;
            }
        }

        input[type="checkbox"]::before {
            content: "";
            width: 5.5vh;
            height: 5.5vh;
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            transform-origin: center;
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--form-control-color);
            /* Windows High Contrast Mode*/
            background-color: white;
        }

        input[type="checkbox"]:checked::before {
            transform: scale(1);
        }

        label[for="checkbox"] {
            padding-left: 2vh;
            font-size: 5.5vh;
            border-radius: 2vh;
            font-family: 'Albus';
            color: #212529;
            margin: auto;
        }
    }
}

.contentFilter {
    width: 30vh;
    margin: 0.5vh auto;
    margin-top: 10vh;
    height: 5vh;
    /*font-size: 0;*/
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    position: relative;

    .bar {
        position: absolute;
        top: calc(100% / 2 - 0.75vh);
        left: 2vh;
        height: 1vh;
        width: calc(100% - 4vh);
        background-color: #10232B;
        border-radius: 0.75vh;
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        outline: none;
        box-shadow: -0.5vh -0.5vh 0.5vh -0.1vh rgba(255,255,255,0.3) inset, 0.5vh 0.5vh 0.5vh 0px rgba(0,0,0,0.3) inset, 0px 0px 0.3vh 0.1vh rgba(0,0,0,0.3);
    }

    .icons {
        display: flex;
        justify-content: space-between;
        position: relative;
        top: -5vh;
        z-index: 1;
        width: 100%;
        height: 100%;
        margin: 0;

        img {
            //left: 50%;
            top: 0;
            transform: translate(0, -50%);
            z-index: 1;
            height: 10vh;
            cursor: pointer;
            margin: 0;
            -webkit-user-drag: none;
        }
    }

    .bar::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        height: 4vh;
        width: 4vh;
        background-color: #cfcfcf;
        border-radius: 50%;
        box-shadow: -0.5vh -0.5vh 0.5vh -0.1vh rgba(0,0,40,0.3) inset, 0.5vh 0.5vh 0.5vh 0px rgba(255,255,255,0.5) inset, 0px 0px 0.3vh 0.1vh rgba(0,0,0,0.3);
    }
}
