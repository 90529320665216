div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.logoSection {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: fit-content;
    width: fit-content;
    transform-origin: top left;

    &.topLeft {
        transform: scale(0.4);
        top: 3vh;
        left: 3vh;
        right: unset;
        bottom: unset;
    }

    .logo {
        width: 50vh;
        margin-right: 1vh;
        margin-top: auto;
        margin-bottom: auto;
        transition: opacity 0.6s;
        opacity: 0;

        &.show {
            opacity: 1;
        }
    }

    .kids {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.gameContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: #FFF100;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    background: white;
    background-image: url('../images/Host/BG.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-image 0.5s ease-in-out;
    overflow: hidden;
    z-index: 0;

    &.kids {
        background-image: url('../images/Host/BG Kids.png');
    }

    &.adult {
        background-image: url('../images/Host/BG Adults.png');
    }

    &.family {
        background-image: url('../images/Host/BG family.png');
    }

    .loadingContainer {
        position: absolute;
        left: 0;
        right: 0;
        /*top: 0;*/
        /* bottom: 0; */
        margin: auto;
        max-width: 700px !important;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        z-index: 1;
    }

    .timerSection {
        position: absolute;
        width: 25vh;
        height: 17.5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #10232B;
        border-top-left-radius: 4vh;
        border-top-right-radius: 4vh;
        margin: auto;
        left: 0;
        right: 0;
        bottom: -2vh;
        padding: 1vh;
        transition: 1s transform;
        transform: translateY(150%);

        &.show {
            transform: translateY(0%);
        }

        .timerText {
            text-align: center;
            font-size: 11vh;
            font-family: "Albus";
            color: white;
            width: fit-content;
            height: fit-content;
            line-height: 1;
            position: absolute;
            left: 40%;
            right: 0;
            bottom: 0;
            margin: auto;
            top: 0;

            &.animate {
                animation: blinker 1s linear;

                @keyframes blinker {
                    0% {
                        color: #FF626F;
                    }

                    100% {
                        color: white;
                    }
                }
            }
        }

        .clock {
            height: 12.5vh;
            position: absolute;
            left: 0;
            right: 0;
            transform: translate(-22%, -7%);
            margin: auto;
        }
    }

    .roomCode {
        position: absolute;
        right: 3vh;
        top: 1vh;
        margin: auto;
        display: flex;
        flex-direction: row;

        .textBox {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            height: fit-content;

            .text {
                text-align: end;
                font-size: 3.5vh;
                font-family: "Albus";
                color: black;

                &:nth-child(1) {
                    transform: translateY(0.75vh);
                }

                &:nth-child(2) {
                    transform: translateY(-0.75vh);
                }

                &.code {
                    font-family: "Albus";
                    font-size: 4vh;
                    font-weight: normal;
                    color: #FF5F6B;
                }
            }

            .iconsBox {
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
                display: flex;
                flex-direction: row;
                width: fit-content;
                height: fit-content;
                z-index: 1000;

                .muteToggle {
                    width: fit-content;
                    height: fit-content;
                    position: relative;
                    margin: auto;
                    cursor: pointer;
                    padding-left: 1vh;

                    .muteIcon {
                        width: auto;
                        height: 5vh;
                    }
                }
            }
        }

        .qrCodeBox {
            background-color: white;
            width: fit-content;
            height: fit-content;
            margin: auto;
            padding: 1vh;
            display: flex;
            border-radius: 1vh;
            margin-left: 1vh;
            margin-top: 1vh;

            .qrCode {
                height: 14vh !important;
                width: 14vh !important;
                margin: auto;
            }
        }
    }

    .mainButton {
        font-size: 10vh;
        font-family: "Albus";
        padding: 3vh;
        margin: 1vh;
        line-height: 1;
        background-color: #10232B;
        color: white;
        border: none;
        border-radius: 2vh;

        &.smaller {
            font-size: 6vh;
        }
    }

    .startButtonSection {
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 11;

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            /*margin-top: -4vh;*/

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: #212B3D;
                margin: 0;
                font: inherit;
                width: 7.5vh;
                height: 7.5vh;
                border-radius: 2vh;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;

                &:hover {
                    background-color: #101C2D !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: 5.5vh;
                height: 5.5vh;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: white;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                padding-left: 2vh;
                font-size: 5.5vh;
                border-radius: 2vh;
                font-family: 'Albus';
                color: #212529;
                margin: auto;
            }
        }
    }

    .playerColumn {
        position: absolute;
        width: 20%;
        height: 75%;
        margin: auto;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: all 2s;
        z-index: 5;


        &.left {
            align-items: start;
            left: -30%;

            &.show {
                left: 0;
            }

            .playerLine {
                margin-right: auto;
                border-top-right-radius: 1vh;
                border-bottom-right-radius: 1vh;
                transform: translateX(-100%);
                z-index: 1;
            }
        }

        &.right {
            right: -30%;
            align-items: end;

            &.show {
                right: 0;
            }

            .playerLine {
                margin-left: auto;
                border-top-left-radius: 1vh;
                border-bottom-left-radius: 1vh;
                transform: translateX(100%);
            }
        }

        .playerLine {
            width: 80%;
            height: 0.5vh;
            background: #BDCCD4;
            transition: 2s transform;

            &.showLine {
                transform: translateX(0%);
            }
        }
    }

    .roundCounterSection {
        position: absolute;
        width: fit-content;
        height: fit-content;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 1s transform;
        transform: translateY(-100%);

        &.show {
            transform: translateY(10%);
        }

        .text {
            height: fit-content;
            width: fit-content;
            text-align: center;
            font-family: "Albus";
            font-size: 5vh;
            color: black;
            font-variant: none;
        }

        .counterSection {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            height: fit-content;

            .counter {
                width: 5vh;
                height: 5vh;
                margin-left: 1vh;
                background-color: #BDCCD4;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                .inner {
                    width: 4vh;
                    height: 4vh;
                    background-color: black;
                    border-radius: 50%;
                    transition: 1s opacity, 1s transform;
                    opacity: 0;
                    transform: scale(0);

                    &.show {
                        opacity: 1;
                        transform: scale(1);
                    }
                }

                &:nth-child(1) {
                    .inner {
                        background-color: #FF485E;
                    }
                }

                &:nth-child(2) {
                    .inner {
                        background-color: #FBB03B;
                    }
                }

                &:nth-child(3) {
                    .inner {
                        background-color: #FFE62E;
                    }
                }

                &:nth-child(4) {
                    .inner {
                        background-color: #8CC63E;
                    }
                }

                &:nth-child(5) {
                    .inner {
                        background-color: #65C8D0;
                    }
                }

                &:nth-child(6) {
                    .inner {
                        background-color: #A567A6;
                    }
                }

                &:nth-child(7) {
                    .inner {
                        background-color: #F99DBB;
                    }
                }

                &:nth-child(8) {
                    .inner {
                        background-color: #10232B;
                    }
                }
            }
        }
    }

    .roundNumber {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        display: flex;

        &.show {
            opacity: 1;
        }

        .number {
            position: relative;
            margin: auto;
            height: 50vh;
            width: auto;
            text-align: center;
        }
    }

    .questionSection {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: 0.5s transform cubic-bezier(0.36, 0, 0.66, -0.56), 0.5s opacity;
        transform: scale(0);
        opacity: 0;
        z-index: 3;

        &.show {
            transition: 0.5s transform cubic-bezier(0.34, 1.56, 0.64, 1), 0.5s opacity;
            transform: scale(1);
            opacity: 1;
        }

        .questionText {
            position: absolute;
            width: 50%;
            height: 33%;
            text-align: center;
            margin: auto;
            bottom: 30vh;
            top: 0;
            left: 0;
            right: 0;
            color: black;
            font-family: "Albus";
            font-size: 10vh;
            line-height: 1;
            display: flex;
            align-items: end;
            justify-content: center;
        }

        .numberText {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            top: 15vh;
            height: fit-content;
            width: fit-content;
            transition: 0.5s transform cubic-bezier(0.36, 0, 0.66, -0.56), 0.5s opacity;
            transform: scale(0);
            opacity: 0;

            &.showNumbers {
                transition: 0.5s transform cubic-bezier(0.34, 1.56, 0.64, 1), 0.5s opacity;
                transform: scale(1);
                opacity: 1;
            }

            .blob {
                position: relative;
                height: 20vh;
                width: auto;
                opacity: 0;
            }

            .text {
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                font-family: "Albus";
                text-align: center;
                color: white;
                font-size: 8.5vh;
                height: fit-content;
                width: fit-content;
            }
        }

        .coloursRow {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            top: 35vh;
            height: fit-content;
            width: 60vh;
            opacity: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            &.showColours {
                opacity: 1;
            }

            .colour {
                width: 14vh;
                height: fit-content;
                margin-left: 2vh;
                margin-right: 2vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: 0.4s transform cubic-bezier(0.36, 0, 0.66, -0.56), 0.4s opacity;
                transform: scale(0);
                opacity: 0;

                &.showColour {
                    transition: 0.4s transform cubic-bezier(0.34, 1.56, 0.64, 1), 0.4s opacity;
                    transform: scale(1);
                    opacity: 1;
                }

                .colourImg {
                    width: 100%;
                    height: auto;
                }

                .colourText {
                    font-family: "Albus";
                    text-align: center;
                    color: black;
                    font-size: 5.5vh;
                    width: fit-content;
                    height: fit-content;
                }
            }
        }
    }

    .colourCaptureSection {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        z-index: 4;
        touch-action: none;
        user-select: none;

        .snatcherAnim {
            height: 90vh;
            width: auto;
            margin: auto;
            transition: transform 1s;
        }

        .textBox {
            text-align: center;
            font-size: 7vh;
            font-family: "Albus";
            color: black;
            width: fit-content;
            height: fit-content;
            line-height: 1;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 5vh;
            transition: 0.5s transform cubic-bezier(0.36, 0, 0.66, -0.56);
            transform: scale(0);

            .smaller {
                font-size: 5vh;
            }

            &.show {
                transition: 0.5s transform cubic-bezier(0.34, 1.56, 0.64, 1);
                transform: scale(1);
            }
        }
    }

    .endGameSection {
        position: absolute;
        height: 80vh;
        width: 160vh;
        margin: auto;
        z-index: 5;
        bottom: 0;
        left: 0;
        right: 0;
        transition: 1s opacity, 1s transform;
        transform: scale(0);
        opacity: 0;

        &.showSection {
            transform: scale(1);
            opacity: 1;
        }

        .winnersSection {
            position: absolute;
            width: 60%;
            height: 70%;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            justify-content: center;
            align-items: center;
            transition: 1s transform;
            transform: translate(-32%, 100vh);

            &.show {
                transform: translate(-32%, 15%) !important;
            }


            .rainbow {
                position: absolute;
                width: 78%;
                height: 100%;
                margin: auto;
                left: 0;
                right: 5%;
                top: 0;
                bottom: 49%;
                transform: rotate(349deg);
            }

            .cloudBox {
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                height: 75%;
                width: fit-content;

                &.one {
                    bottom: 17%;
                    left: 58%;
                }

                &.two {
                    top: 29%;
                    right: 55%;
                }

                .cloud {
                    position: relative;
                    height: 100%;
                    width: auto;

                    &.back {
                        position: absolute;
                    }

                    &.front {
                        z-index: 5;
                    }
                }

                .playerGroup {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    margin: auto;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;

                    .winner {
                        position: absolute;
                        height: 40%;
                        width: auto;
                        margin: auto;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 8%;

                        &:nth-child(1) {
                            transform: translateX(10%);
                            z-index: 3;
                        }

                        &:nth-child(2) {
                            transform: translateX(-10%);
                            z-index: 2;
                        }

                        &:nth-child(3) {
                            transform: translateX(30%);
                            z-index: 1;
                        }

                        &:nth-child(4) {
                            transform: translateX(-30%);
                            z-index: 1;
                        }
                    }
                }
            }
        }

        .buttonsSection {
            position: absolute;
            width: 60%;
            height: 30%;
            margin: auto;
            left: 0;
            bottom: 0;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: row;
            transition: 0.5s transform cubic-bezier(0.36, 0, 0.66, -0.56);
            transform: scale(0);

            &.show {
                transition: 0.5s transform cubic-bezier(0.34, 1.56, 0.64, 1);
                transform: scale(1);
            }

            .button {
                font-size: 6vh;
                font-family: "Albus";
                padding: 2vh;
                padding-left: 3vh;
                padding-right: 3vh;
                margin: 1vh;
                line-height: 1;
                background-color: #10232B;
                color: white;
                border: none;
                border-radius: 2vh;
                width: 45%;
            }
        }

        .leaderboardSection {
            position: absolute;
            width: 40%;
            height: 100%;
            margin: auto;
            right: 0;
            justify-content: start;
            align-items: center;
            display: flex;
            flex-direction: column;
            transition: 0.75s transform;
            transform: translateX(50vw);
            opacity: 0;

            &.show {
                transform: translateX(0%);
                opacity: 1;
            }

            .tableRow {
                position: relative;
                font-family: "Albus";
                font-size: 4vh;
                display: flex;
                flex-direction: row;
                width: 90%;
                align-items: center;
                padding-right: 10%;
                padding-left: 2%;

                &.header {
                }
            }

            .player {
                border-radius: 1.5vh;
                margin-top: 4%;

                &:nth-of-type(1) {
                    margin-top: 1%;
                    background: #64BFCE;
                }

                &:nth-of-type(2) {
                    background: #8CC63F;
                }

                &:nth-of-type(3) {
                    background: #FFE630;
                }

                &:nth-of-type(4) {
                    background: #FAB03B;
                }

                &:nth-of-type(5) {
                    background: #FF5E6A;
                }

                &:nth-of-type(6) {
                    background: #F99CBB;
                }

                &:nth-of-type(7) {
                    background: #A467A3;
                }

                &:nth-of-type(8) {
                    background: #AA5D3F;
                }

                div {
                    font-family: "Albus";
                    font-size: 4vh;
                    color: white;
                    border: none;
                }
            }

            .position {
                text-align: start;
                width: 15%;
            }

            .name {
                width: 65%;
                text-align: center;
            }

            .score {
                width: 15%;
                text-align: center;
                justify-content: center;
                align-items: center;
                display: flex;
            }

            .potato {
                position: absolute;
                width: auto;
                height: 9.5vh;
                margin: auto;
                right: 0;
                bottom: 0;
                top: 0;
            }
        }
    }
}
