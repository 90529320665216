

.player {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    margin: 0.5vh;
    justify-content: start;
    align-items: center;
    transition: 1s height, 1s transform;
    height: 0%;
    transform: scale(0);
    z-index: 2;

    &.show {
        transform: scale(1.15) translateX(5%);
        height: 22%;
    }

    .spotlight {
        position: absolute;
        width: 12vh;
        height: 12vh;
        border-radius: 50%;
        background: #ECEF66;
        margin: auto;
        top: 1vh;
        left: 0vh;
    }

    .potato {
        position: relative;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 15vh;
        width: auto;
    }

    .textSection {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 50%;

        .name {
            font-family: "Albus";
            font-size: 2.75vh;
            color: black;
            min-width: 100%;
            width: fit-content;

            &.large {
                font-size: 3.5vh;
            }

            &.medium {
                font-size: 2.75vh;
            }

            &.small {
                font-size: 2vh;
            }
        }

        .points {
            color: black;
            font-size: 2.5vh;
            font-family: "Albus";
        }

        .colourList {
            position: relative;
            height: 5vh;
            width: fit-content;
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;

            &.reverse {
                flex-direction: row-reverse;
            }

            .colour {
                position: relative;
                height: 100%;
                width: auto;
                transition: 0.25s opacity, 0.25s transform cubic-bezier(0.36, 0, 0.66, -0.56);
                transform: scale(0);
                opacity: 0;

                &.show {
                    transition: 0.25s opacity, 0.25s transform cubic-bezier(0.34, 1.56, 0.64, 1);
                    transform: scale(1);
                    opacity: 1;
                }

                &:nth-child(n+2) {
                    margin-left: 1vh;
                }

                .innerColour {
                    height: 100%;
                    width: auto;
                }
            }

            .coinSection {
                height: 12vh;
                width: 6vh;
                margin: 2vh;
            }
        }
    }

    &.reverse {
        flex-direction: row-reverse;
        justify-content: end;

        &.show {
            transform: scale(1.15) translateX(-5%);
            height: 22%;
        }

        .spotlight {
            right: 0vh;
            left: unset;
        }

        .textSection {
            align-items: end;

            .name {
                text-align: end;
            }

            .points {
                text-align: end;
            }

            .colourList {
                /*flex-direction: row-reverse;*/

                .colour {
                    margin-right: 1vh;
                    margin-left: unset !important;

                    &:last-child {
                        margin-right: unset;
                    }
                }
            }
        }
    }
}
