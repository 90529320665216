[contenteditable] {
    outline: 0px solid transparent;
}


:global(div) {
    touch-action: manipulation;
}

:global(img) {
    touch-action: manipulation;
}

div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.clientContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 600px !important;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-image: url(../images/BG.png);
    background-size: cover;
    background-repeat: no-repeat;
    transition: background-image 0.5s ease-in-out;

    &.kids {
        background-image: url('../images/BG Kids.png');
    }

    &.adult {
        background-image: url('../images/BG Adults.png');
    }

    &.family {
        background-image: url('../images/BG family.png');
    }

    .connectionIssueContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        flex-direction: column;
        z-index: 10000;
        justify-content: center;
        align-items: center;

        .connectionText {
            width: fit-content;
            height: fit-content;
            font-size: 30px;
            color: white;
            font-family: Albus;
            text-align: center;
        }

        .refreshButton {
            font-family: 'Lucida Sans Unicode';
            padding: 10px;
            line-height: 1;
            font-weight: bolder;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;
            width: fit-content;
            height: fit-content;
            font-size: 75px;
            color: white;
            border-radius: 20px;
            background-color: #FF485E;
            box-shadow: 0px 5px #C1272D;
            cursor: pointer;
        }
    }

    .pauseContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        z-index: 10000;

        .pauseText {
            margin: auto;
            width: fit-content;
            height: fit-content;
            font-size: 8vh;
            color: white;
            font-family: Albus;
        }
    }

    .skipContainer {
        background-color: black;
        border-radius: 1vh;
        width: fit-content;
        height: fit-content;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;

        .skipButton {
            font-size: 4vh;
            color: white;
            text-align: center;
            padding: 1vh;
            margin: auto;
            position: relative;
            font-family: Albus;
        }
    }

    .questionSection {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .questionBox {
            height: fit-content !important;
            width: 95%;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            margin: auto;
            margin-bottom: unset;
            margin-top: unset;
            padding-top: 2%;

            .questionText {
                font-size: 1.75rem;
                color: #000000;
                text-align: center;
                margin: auto;
                height: fit-content;
                width: 95%;
                font-family: Albus;
            }

            .requiredColoursText {
                font-size: 1.5rem;
                color: #9EAAAF;
                text-align: center;
                margin: auto;
                font-family: Albus;
                margin-bottom: unset;
                margin-top: 2%;
            }
        }

        .coloursSection {
            height: fit-content;
            width: 90%;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            margin: auto;
            max-height: 80%;
            margin-top: unset;

            .coloursBox {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                margin: auto;
                overflow-y: scroll;

                .colour {
                    position: relative;
                    width: 30%;
                    height: fit-content;
                    margin: auto;
                    margin-top: 1%;
                    margin-bottom: 1%;
                    display: flex;
                    margin-left: 1%;
                    margin-right: 1%;
                    padding: 1%;
                    flex-direction: column;

                    &.selected {
                        .colourBg {
                            opacity: 1;
                        }

                        .colourName {
                            color: #FFFFFF;
                        }
                    }

                    .colourBg {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        margin: auto;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        opacity: 0;

                        &.show {
                            opacity: 1;
                        }
                    }

                    .colourImg {
                        width: 80%;
                        height: auto;
                        margin: auto;
                        z-index: 1;
                        margin: auto;
                    }

                    .colourName {
                        font-size: 1.3rem;
                        text-align: center;
                        margin: auto;
                        z-index: 1;
                        font-family: Albus;
                        color: #10232B;

                        &.white {
                            color: #FFFFFF;
                        }
                    }

                    &.disabled {
                        opacity: 0.25;
                    }
                }
            }
        }

        .answersSection {
            width: fit-content;
            height: 30%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            margin: auto;

            .answerWrapper {
                overflow: unset;
                margin: auto;
                max-width: 10vh;
                margin-left: unset;
                margin-right: unset;
                height: 75%;

                .answerColour {
                    width: 12vh;
                    height: 100%;
                    border-radius: 2vh;
                    position: relative;
                    transform: translateX(-50%);
                    left: 50%;
                }
            }
        }

        .buttonsSection {
            width: 100%;
            height: 15%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            .button {
                width: fit-content;
                height: fit-content;
                background-color: black;
                margin: auto;
                font-size: 3.5vh;
                font-weight: bold;
                color: white;
                text-align: center;
                padding-left: 3vh;
                padding-right: 3vh;
                border-radius: 0.5vh;
                padding-bottom: 0.25vh;
                padding-top: 0.25vh;
                box-shadow: 0px 0.5vh grey;

                &.disabled {
                    opacity: 0.3;
                }
            }
        }
    }

    .capturedSection {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #10232B;
        display: flex;
        z-index: 21;
        display: flex;
        padding: 15%;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .captureIcon {
            height: 145px;
            width: fit-content;
        }

        .capturedText {
            width: fit-content;
            height: fit-content;
            font-size: 5vh;
            color: white;
            font-family: Albus;
            text-align: center;
        }
    }

    .captureListSection {
        position: absolute;
        width: 95%;
        height: 95%;
        min-height: 600px;
        background-color: #10232B;
        display: flex;
        z-index: 20;
        flex-direction: column;
        border-radius: 30px;
        padding: 20px;
        margin: auto;
        left: 0;
        right: 0;
        top: 2.5%;

        .captureTitle {
            width: 100%;
            height: -moz-fit-content;
            height: fit-content;
            font-size: 40px;
            color: white;
            font-family: Albus;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            line-height: 1;
        }
    }

    .captureIcon {
        height: 145px;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .closeButton {
        font-size: 50px;
        text-align: center;
        font-family: Albus;
        color: white;
        position: absolute;
        margin: auto;
        top: 2vh;
        left: 2vh;
        line-height: 1;
    }

    .captureList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: auto;
        width: 100%;
        height: fit-content;
        max-height: 75%;
        overflow-y: auto;
        margin-top: 3%;



        .captureSelection {
            width: 32%;
            height: fit-content;
            background-color: #10232B;
            border-radius: 15px;
            margin: auto;
            padding: 0.5vh;
            padding-bottom: 10px;
            padding-top: unset;
            overflow: hidden;
            margin-bottom: 2%;
            margin-top: 2%;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;

            &.last {
                margin-left: 1%;
            }

            .potato {
                width: 100%;
                height: fit-content;
                margin: auto;
            }

            .text {
                font-size: 15px;
                text-align: center;
                font-family: Albus;
                text-overflow: ellipsis;
                color: white;
                line-height: 1;

                &.scoreTop {
                    color: #FF485E !important;
                }
            }

            &.selected {
                background-color: white;
                color: #10232B;

                .text {
                    color: #10232B;
                }
            }
        }
    }

    .buttonsSection {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin: auto;
        position: absolute;
        bottom: 20px;
        right: 20px;

        .captureButton {
            width: fit-content;
            height: fit-content;
            background-color: #FF485E;
            margin: auto;
            margin-right: unset;
            font-size: 40px;
            color: white;
            text-align: center;
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 17.5px;
            padding-bottom: 5px;
            padding-top: 5px;
            box-shadow: 0px 5px #C1272D;
            font-family: Albus;

            &.disabled {
                opacity: 0.3;
            }
        }
    }
}



