@font-face {
    font-family: "Albus";
    src: url('./fonts/Gustav-Brun-Albus.otf') format('opentype');
}

@font-face {
    font-family: "Interstate";
    src: url('./fonts/Interstate-Regular.otf') format('opentype');
}

.exampleFont {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}